.navbar,
.navbar-default {
  background-color: black;
}

.wrapperDark {
  background-color: #121212;
}
.wrapperLight {
  background-color: white;
}

.newMargin {
  margin-top: 20px;
}

.wrapperDark {
  /* display: flex;
    justify-content: center; */
  background-color: #121212;
  /* transition: 0.3s;
    min-height: 100vh; */
  position: fixed;
  top: 10%;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}
.wrapperLight {
  display: flex;
  justify-content: center;
  transition: 0.3s;
  padding-bottom: 20px;
}

.darkWrapper {
  transition: 0.3s;
  background-color: #121212;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../../assets/img/not-found.jpg");
  background-size: contain;
  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: -1;

  display: flex;
  justify-content: center;
  color: white;
}

.lightWrapper {
  transition: 0.3s;
  background-color: white;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url("../../assets/img/not-found.jpg");
  background-size: contain;
  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: -1;

  display: flex;
  justify-content: center;
  color: black;
}

.content {
  display: flex;
  flex-direction: column;
}

.message {
  padding-top: 200px;
  padding-left: 100px;
  padding-right: 100px;
  font-size: 50pt;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.searchBarText {
  padding-top: 20px;
  padding-left: 100px;
  padding-right: 100px;
  font-size: 30pt;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.searchBar {
  padding-top: 40px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin-left: 10px;
  margin-right: 10px;
}

.searchBarOuter {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-width: 700px) {
  .message {
    font-size: 30pt;
    padding-left: 30px;
    padding-right: 30px;
  }

  .searchBarText {
    font-size: 20pt;
    padding-left: 30px;
    padding-right: 30px;
  }
}

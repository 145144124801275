.jumbotronWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.jumbotronDiv {
  margin-top: 20px;
  width: 50rem;
}

.chartWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.headerWrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.textWrapper {
  width: 40vw;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.breakdownDiv {
  display: flex;
  flex-direction: row;
  font-size: 15pt;
  margin-top: 20px;
}

.catDiv {
  display: block;
  text-align: left;
  font-weight: bold;
  width: 50%;
}

.numberDiv {
  display: block;
  text-align: right;
  margin-left: 10px;
  width: 40%;
}

.jumboOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.jumboInner {
  margin-top: 20px;
  width: 70vw;
}

@media (max-width: 991px) {
  .contentWrapper {
    flex-direction: column;
  }

  .textWrapper {
    width: 100vw;
    justify-content: center;
  }

  .wrapper {
    height: 100%;
    background: #121212;
  }
}

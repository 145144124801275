.wrapperDark {
  background-color: #121212;
  display: flex;
  transition: 0.3s;
  padding-bottom: 10px;
}
.wrapperLight {
  display: flex;
  justify-content: center;
  transition: 0.3s;
  position: relative;
  padding-bottom: 10px;
}

.inner {
  width: 50rem;
  justify-content: center;
}

.stackTop {
  width: 30rem;
  justify-content: center;
}

.container {
  position: relative;
}

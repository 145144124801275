.wrapper {
  width: 100%;
  height: 100vh;
  margin-top: -100px;
  padding-top: 100px;
}

.text {
  font-family: "Montserrat", sans-serif;
}

.jumboOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.jumboInner {
  margin-top: 20px;
  width: 70vw;
}

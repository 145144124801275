.label {
  display: inline-block;
}

.container {
  text-align: center;
}

.wrapper {
  display: flex;
  justify-content: center;
}
.inner {
  width: 50rem;
}

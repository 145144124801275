.jumboWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
}

.wrapper {
  margin-top: 20px;
  padding-left: 40px;
  height: 100%;
}

.wrapperDark {
  margin-top: 20px;
  padding-left: 40px;
  height: 100%;
  background: #121212;
}

.investmentDiv {
  display: flex;
  flex-direction: column;
}

.table {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-right: 20px;
}

.contentWrapper {
  margin-top: 20px;
}

tr {
  cursor: pointer;
}

.green {
  color: #34c759;
}

.red {
  color: #ff3b30;
}

.none {
}

h3 {
  margin-bottom: 30px;
  margin-right: 10px;
}

.netChange {
  display: flex;
  flex-direction: row;
}

.alertDiv {
  margin-right: 20px;
}

.wrapperLight {
  display: flex;
  flex-direction: column;
}

.wrapperDark {
  display: flex;
  flex-direction: column;
  background: #121212;
  height: 100%;
}

.infoTitle {
  font-size: 20pt;
  font-weight: bold;
  padding-left: 80px;
}

.infoBox {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-top: 20px;
  font-size: 16pt;
  padding-left: 80px;
}

.headerColumn {
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-weight: bold;
  width: 20vw;
}

.dataColumn {
  display: flex;
  flex-direction: column;
  justify-content: right;
  width: 15vw;
  margin-left: 20px;
}

.infoHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.followButtonDiv {
  margin-top: 11px;
  margin-left: 20px;
}

.followButtonDisabled {
  margin-right: 10px;
  pointer-events: none;
}

.followButton {
  margin-right: 10px;
}

.title {
  font-size: 30pt;
  font-weight: bold;
  padding-left: 65px;
  margin-right: 10px;
}

.followedStocksWrapperDark {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding-bottom: 30px;
  padding-left: 80px;
  background-color: #121212;
  overflow: scroll;
}

.followedStocksWrapperLight {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding-bottom: 30px;
  padding-left: 80px;
  overflow: scroll;
}

.followedTitle {
  font-size: 20pt;
}

.followedStockButton {
  margin-left: 20px;
}

.buySellDiv {
  display: flex;
  flex-direction: row;
  margin-top: 11px;
  margin-left: 65px;
}

.buySellField {
  margin-right: 10px;
}

.alertDiv {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 10px;
}

.infoBlock {
  display: flex;
  flex-direction: row;
}

@media (max-width: 991px) {
  .infoTitle {
    padding-left: 20px;
  }

  .infoBox {
    padding-left: 20px;
    flex-direction: column;
  }

  .headerColumn {
    width: 50%;
  }

  .followedStocksWrapperDark {
    padding-left: 20px;
  }

  .followedStocksWrapperLight {
    padding-left: 20px;
  }

  .title {
    padding-left: 20px;
  }

  .buySellDiv {
    margin-left: 20px;
  }
}

.wrapperLight {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}
.wrapperDark {
  display: flex;
  justify-content: center;
  background-color: #121212;
  padding-bottom: 10px;
}
.inner {
  width: 50rem;
  justify-content: center;
}

.darkModeToggles {
  margin-left: 20px;
  margin-top: -10px;
  margin-bottom: 10px;
}

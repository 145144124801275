.wrapper {
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.form {
  font-family: "Montserrat", sans-serif;
  width: 50rem;
}

.innerWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50rem;
}

.innerDiv {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

@media (max-width: 991px) {
  .wrapper {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.all {
  font-family: "Montserrat", sans-serif;
}

.header {
  font-weight: bold;
  font-size: 100pt;
}
.bodyDiv {
  padding-top: 75px;
  /*padding-right: 10px;
  padding-left: 10px;
  */ 
}

.darkMode {
  transition: 0.3s;
  font-family: "Montserrat", sans-serif;
  color: white;
  background: #121212;
  height: 100vh;
}

.lightMode {
  transition: 0.3s;
  font-family: "Montserrat", sans-serif;
  color: black;
  background: white;
  height: 100vh;
}

.navContainer {
  /* margin-bottom: 100px; */
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  width: 100%;
}

.bar {
  width: 72%;
  padding-right: 10px;
  border-color: white;
  margin-left: 10px;
}

.searchButton {
  width: 18%;
}

.toggle {
  width: 10%;
}

.menu {
  margin-right: 10px;
  width: 100%;
  overflow: scroll;
}

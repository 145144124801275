.cont {
  text-align: center;
}

.alert {
  display: inline-block;
}

.mycol {
  margin-top: 200px;
}
